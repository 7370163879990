import { render, staticRenderFns } from "./HotVideo1.vue?vue&type=template&id=65b89084&scoped=true"
import script from "./HotVideo1.vue?vue&type=script&lang=js"
export * from "./HotVideo1.vue?vue&type=script&lang=js"
import style0 from "./HotVideo1.vue?vue&type=style&index=0&id=65b89084&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b89084",
  null
  
)

export default component.exports